import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Surprise Surprise`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`Klask/ Klūsk`}</h3>
    <p><a parentName="p" {...{
        "href": "http://klask.me/services/rules-in-english/"
      }}>{`Klask rules`}</a>{` – `}<a parentName="p" {...{
        "href": "http://intl.target.com/p/klask-board-game/-/A-50939234"
      }}>{`Buy Klask`}</a></p>
    <h3>{`Rules to the dumbest card game ever`}</h3>
    <p>{`Apparently the game is actually called "`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Around_the_World_(card_game)"
      }}>{`Around the world`}</a>{`" – `}<a parentName="p" {...{
        "href": "http://www.dictionary.com/browse/solidarity"
      }}>{`Solidarity`}</a></p>
    <h3>{`Akwardy walk-iny`}</h3>
    <p><a parentName="p" {...{
        "href": "http://grexmusicus.fi/"
      }}>{`grex musicus`}</a></p>
    <h3>{`Serious presentation avoidance`}</h3>
    <p><a parentName="p" {...{
        "href": "http://giphy.com/search/funny-cat"
      }}>{`cat gifs`}</a></p>
    <h3>{`A philosophy we want to bring into the world`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/"
      }}>{`booncon`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/booncon-a-new-year-a-new-dawn/"
      }}>{`more about booncon`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/the-eagle-has-landed/"
      }}>{`even more about booncon`}</a></p>
    <h3>{`Offspring reluctance`}</h3>
    <p><a parentName="p" {...{
        "href": "http://pixels.fi/"
      }}>{`booncon PIXELS`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/better_projects/"
      }}>{`how we work`}</a>{` – `}<a parentName="p" {...{
        "href": "http://pixels.fi/en/about-us"
      }}>{`PIXELS Team`}</a>{`: 9 nationalities, 15 languages`}</p>
    <h3>{`Offspring promiscuity`}</h3>
    <p><a parentName="p" {...{
        "href": "http://tinytinbox.com/"
      }}>{`Tiny Tin Box`}</a></p>
    <h3>{`Something more`}</h3>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Project_X_(2012_film)"
      }}>{`Project X`}</a></p>
    <h3>{`Luki of Finland`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.hs.fi/kaupunki/a1471141434775?jako=c417afac6e5b9436f453d6a0359769b2"
      }}>{`HS article (FIN)`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/entrepreneur-lukas-hafner-interviewed-by-helsingin-sanomat/"
      }}>{`article translation`}</a></p>
    <h3>{`Fidel Castro 2.0`}</h3>
    <p><a parentName="p" {...{
        "href": "http://supertop.co/castro/"
      }}>{`Castro`}</a></p>
    <h3>{`Unconditional bye bye`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      